import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/ai-yacht-boat-chatbot/Hero"
import TrustedBy from "../components/ai-yacht-boat-chatbot/TrustedBy"
import MainFeatures from "../components/ai-yacht-boat-chatbot/MainFeatures"
import LearnWhy from "../components/ai-yacht-boat-chatbot/LearnWhy"
import KeyFeatures from "../components/ai-yacht-boat-chatbot/KeyFeatures"
import ConsistencyAndReliability from "../components/ai-yacht-boat-chatbot/ConsistencyAndReliability"
import ImmediateResponseTime from "../components/ai-yacht-boat-chatbot/ImmediateResponseTime"
import SEOFriendly from "../components/ai-yacht-boat-chatbot/SEOFriendly"
import PreTrainedForYachtingIndustry from "../components/ai-yacht-boat-chatbot/PreTrainedForYachtingIndustry"
import KnowledgeBase from "../components/ai-yacht-boat-chatbot/KnowledgeBase"
import IntegratesWithYourListingsAPI from "../components/ai-yacht-boat-chatbot/IntegratesWithYourListingsAPI"
import IntegrationWithCRMs from "../components/ai-yacht-boat-chatbot/IntegrationWithCRMs"
import Operates247 from "../components/ai-yacht-boat-chatbot/Operates247"
import HumanLikeConversation  from "../components/ai-yacht-boat-chatbot/HumanLikeConversation"
import OtherFeatures   from "../components/ai-yacht-boat-chatbot/OtherFeatures"
import ContactForm from "../components/ContactForm"
import Pricing from "../components/ai-yacht-boat-chatbot/Pricing"

const data = {
  canonical: "/ai-yacht-boat-chatbot",
  title: "Yachting.AI - AI Yacht ChatBot",
};

const IndexPage = () => {
  const [product, setProduct] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  return (
    <Layout
      breadcrumbs={true}
      breadcrumbsJustMeta={true}
      canonical={data.canonical}
      title={data.title}
    >
      <Seo
        title={"AI Yacht Chat"}
        lang={"en-US"}
        description={"AI Yacht Chat - Increase your sales with our AI ChatBot for Yachting Industry"}
        canonical={"https://yachting.ai/ai-yacht-boat-chatbot"}
      />

      <Hero /> 
      {/* <TrustedBy /> */}
      <MainFeatures />
      <LearnWhy />
      <KeyFeatures />
      <ConsistencyAndReliability />
      <ImmediateResponseTime />
      <SEOFriendly />
      <PreTrainedForYachtingIndustry />
      <KnowledgeBase />
      <IntegratesWithYourListingsAPI />
      <IntegrationWithCRMs />
      <Operates247 />
      <HumanLikeConversation />
      <OtherFeatures />
      <Pricing />

      <section className={"py-16 md:py-32 p-5 bg-gray-900"}>
        <div className={"max-container"}>
          <h2
            className={"text-var-6xl lg:pb-14 text-left lg:text-center font-black gradient-text-fire"}
          >
            Contact Us
          </h2>
          <ContactForm />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
