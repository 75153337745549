import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"

const ImmediateResponseTime = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 bg-gray-900"}>
            <div className={"max-container"}>
                <div className={"md:flex relative justify-center w-full"}>
                    <div className={"md:w-1/2 lg:w-1/3"}>
                        <StaticImage

                            src={"../../images/yachting-ai-yacht-chat-bot-immediate-response-time.png"}
                            placeholder={"blurred"}
                            alt={"Yachting.AI Tailored AI Yacht Browser"}
                            className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300"
                        />
                        <div className="text-gray-400 pt-2 pb-5">* Actual Conversation with WhiteCube AI Yacht Chatbot [Names and Contact Details Altered]</div>
                        
                    </div>
                    <div className={"md:w-1/2 md:ml-20 flex flex-col justify-center "}>
                        <h2
                            className={"text-var-6xl text-left font-bold leading-none pb-10 md:pt-0 pt-10 gradient-text-fire"}
                        >
                            Immediate Response Time
                        </h2>
                        <p
                            className={"text-var-2xl md:my-4 col-start-1 row-start-2 lg:w-full text-gray-300"}
                        >
                            <Sentence>Traditional chat systems often leave users waiting and consultants juggling multiple clients,
                                leading to extended response times and frustrated customers. With our AI Chat, there are no
                                queues and you get near-instantaneous replies. Experience a seamless, intuitive, and enjoyable
                                mobile searching with our AI-powered solution.</Sentence>
                        </p>
                        <button
                            className={"relative text-center gradient-bg-fire hover:gradient-bg-purple text-black hover:text-white font-bold text-xl rounded-full ml-0 mt-10 py-4 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                            onClick={() => {
                                setProduct(Product.AI_YACHT_BROWSER);
                                setShowModal(true);
                            }}
                        >
                            Get More Info
                        </button>
                    </div>
                </div>
            </div>

            <RequestDemo
              // product={"AI Yacht ChatBot"}
              product={product}
              onClose={() => setShowModal(false)}
              show={showModal}
            />

        </section>
);
}

export default ImmediateResponseTime
