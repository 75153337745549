import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"
import Messages from "../../components/ai-yacht-boat-chatbot/messagesOperates247.js"

const Operates247 = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
      <section className={"py-16 md:py-32 p-5 bg-gray-900"}>
        <div className={"max-container"}>
          <div className={"md:flex relative justify-center w-full"}>
            <div className={"flex md:w-1/2 md:visible lg:w-1/3"}>
                <Messages />
              
            </div>
            <div className={"md:w-1/2 md:ml-20 flex flex-col justify-center "}>
              <h2
                className={
                  "text-var-6xl block font-black md:mb-12 lg:w-full gradient-text-fire"
                }
              >
                Operates 24/7 and is Resilient to Traffic Spikes
              </h2>
              <p
                className={
                  "text-var-2xl my-4 col-start-1 row-start-2 lg:w-full text-gray-300"
                }
              >
                <Sentence>
                  The AI Yacht Chat is always on and always available, 7 days
                  a week, 24 hours a day. Moreover, unlike chats with human
                  operators, it performs equally well whether it's serving one
                  person or 100 people simultaneously. In traditional chats
                  managed by humans, when there's a sudden surge in customer
                  numbers, for instance, during an email blast campaign, events
                  like boat shows, or other marketing campaigns, the waiting
                  time to connect with an operator dramatically increases. This
                  is because such chats have a limited number of operators. Our
                  solution doesn't have such limitations and can handle peak
                  traffic hours on the website without any issues.
                </Sentence>
              </p>
              <button
                className={
                  "relative text-center gradient-bg-fire hover:gradient-bg-purple text-black hover:text-white font-bold text-xl rounded-full ml-0 py-4 px-8 mt-10 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"
                }
                onClick={() => {
                  setProduct(Product.AI_YACHT_BROWSER)
                  setShowModal(true)
                }}
              >
                Get More Info
              </button>
            </div>
          </div>
        </div>
        <RequestDemo
          // product={"AI Yacht ChatBot"}
          product={product}
          onClose={() => setShowModal(false)}
          show={showModal}
        />
      </section>
    )
}

export default Operates247
