import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";


import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal";

const MainFeatures = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className="py-16 md:py-32 p-5 text-gray-300 bg-gray-900 body-font">
            <div className="max-container mx-auto">
                <div className="text-center mb-20">
                    <h2 className="text-var-6xl text-center text-off-black font-bold leading-none pb-10">Yachting AI ChatBot Main Features</h2>
                    <p className="leading-relaxed xl:w-2/4 lg:w-3/4 text-xl mx-auto text-gray-200 text-opacity-80">
                        This is whats make our AI ChatBot be so efficent in leads capture
                    </p>
                    <div className="flex mt-6 justify-center">
                        <div className="w-16 h-1 rounded-full bg-pink-700 inline-flex"></div>
                    </div>
                </div>
                <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                    <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-pink-400 mb-5 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                            </svg>
                        </div>
                        <div className="flex-grow">
                            <h2 className="text-2xl title-font font-medium mb-3">Relalible</h2>
                            <p className="leading-relaxed text-xl">
                                The AI Yacht Chat consistently provides high-quality customer service, unaffected by external factors, demonstrating patience and understanding at all times.
                            </p>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-pink-400 mb-5 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                                <circle cx="6" cy="6" r="3"></circle>
                                <circle cx="6" cy="18" r="3"></circle>
                                <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>
                        </div>
                        <div className="flex-grow">
                            <h2 className="text-2xl title-font font-medium mb-3">Consistent</h2>
                            <p className="leading-relaxed text-xl">The AI Yacht Chat offers consistent, high-quality service in yachting, surpassing traditional chat systems that may suffer from variable agent training and turnover.</p>

                        </div>
                    </div>
                    <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                        <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-pink-400 mb-5 flex-shrink-0">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </div>
                        <div className="flex-grow">
                            <h2 className="text-white text-2xl title-font font-medium mb-3">Listings API</h2>
                            <p className="leading-relaxed text-xl">The AI Yacht Chat, integrated with the listings feed, instantly searches for yachts based on client interest, driving site traffic, reducing bounce rate, and increasing quality leads.</p>

                        </div>
                    </div>
                </div>
                <button 
                    className="text-off-white relative my-10 flex h-12 w-full mx-auto items-center justify-center px-8 before:text-white before:absolute before:inset-0 before:rounded-full before:bg-pink-800 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                    onClick={() => {
                        setProduct(Product.AI_CHATBOT);
                        setShowModal(true);
                    }}
                >
                    <span className="relative text-xl font-semibold text-off-white">Get More Info</span>
                </button>
            </div>

            <RequestDemo
              // product={"AI Yacht ChatBot"}
              product={product}
              onClose={() => setShowModal(false)}
              show={showModal}
            />
        </section>
);
}

export default MainFeatures
