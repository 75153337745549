import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal";

const Hero = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"bg-gradient-to-br py-10 md:py-40 mt-10 p-5 from-pink-500 to-purple-600"}>
            <div className={"max-container"}>
                <div
                    className={"flex flex-col md:flex-row relative justify-center w-full"}
                >
                    <div
                        className={"flex order-2 md:order-1 flex-col justify-center md:w-1/2"}
                    >
                        <h1 className={"text-var-6xl text-off-white font-bold leading-none py-10"}>
                            AI ChatBot<br /> Designed For Yachtig Industry
                        </h1>
                        <p className={"text-off-white text-2xl"}>The only solution specifically designed and tailored for the Yachting Industry.</p>
                        <button 
                            className="relative my-10 flex h-12 w-full mx-auto items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                            onClick={() => {
                                setProduct(Product.AI_CHATBOT);
                                setShowModal(true);
                            }}
                        >
                            <span className="relative text-xl font-semibold text-purple-600">Try it Now</span>
                        </button>
                    </div>

                    <div
                        className={"order-1 sm:px-32 md:px-0 md:w-1/2 flex flex-col justify-center md:ml-12 mb-8 md:mb-0 md:mt-0"}
                    >
                        <StaticImage
                            src={"../../images/yachting-ai-rocket.png"}
                            placeholder={"blurred"}
                            alt={"AI Yacht Chat"}
                            className="mx-auto w-full"
                            />

                    </div>
                </div>
            </div>
            <RequestDemo
              // product={"AI Yacht ChatBot"}
              product={product}
              onClose={() => setShowModal(false)}
              show={showModal}
            />
        </section>
);
}

export default Hero
