import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"

const IntegrationWithCRMs = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 max-container"}>
            <div className={"md:flex relative w-full"}>
                <div className={"flex flex-col justify-center md:w-1/2"}>
                    <h2
                        className={"text-var-6xl text-left font-bold leading-none md:pb-10"}
                    >
                        Integration with CRMs
                    </h2>
                    <p
                        className={"text-var-2xl my-4 col-start-1 row-start-2 w-full md:w-auto lg:w-full"}
                    >
                        <Sentence>
                            Through the built-in webhook mechanism, you can connect the AI Yacht Chat with any CRM system (this might require coding or the use of no- code tools like Make or Zapier).

                            <br />
                            <br />
                            While it natively supports HubSpot, other CRM systems can be easily integrated as well. Our chat automatically summarizes the chat content, extracts the customer's contact details, and sends a link to the entire conversation to the CRM system.
                        </Sentence>
                    </p>
                    <button
                        className={"relative inline-block text-center gradient-bg-purple hover:gradient-bg-fire text-white hover:text-black font-bold text-xl rounded-full ml-0 md:w-auto w-full py-4 mt-8 md:my-8 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                        onClick={() => {
                            setProduct(Product.AI_YACHT_SEARCH);
                            setShowModal(true);
                        }}
                    >
                        Get More Info
                    </button>
                </div>
                <div
                    className={"w-1/2 md:ml-10 md:pt-0 pt-10 hidden md:visible flex flex-col justify-center md:inline-block"}
                >
                    <StaticImage
                        src={"../../images/yachting-ai-yacht-boat-chat-hubspot-integration.png"}
                        objectFit={"contain"}
                        placeholder={"blurred"}
                        alt={"AI Yacht Chat integrates with an CRM"}
                    />
                </div>
            </div>

            <RequestDemo
                // product={"AI Yacht ChatBot"}
                product={product}
                onClose={() => setShowModal(false)}
                show={showModal}
            />
        </section>
);
}

export default IntegrationWithCRMs
