import React, { useEffect, useRef, useState } from 'react'

const MessagesComponent = () => {
  const messagesRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsVisible(true)
        observer.disconnect()  // Stop observing after first intersection
      }
    })

    if (messagesRef.current) {
      observer.observe(messagesRef.current)
    }

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (isVisible && messagesRef.current) {
      const messages = Array.from(messagesRef.current.querySelectorAll('.message'))
      messages.forEach((message, index) => {
        setTimeout(() => {
          message.classList.remove('hidden')
        }, index * 1000)  // 1 second delay
      })
    }
  }, [isVisible])

  return (
    <div
      id="messages"
      ref={messagesRef}
      className="max-w-[550px] flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch overflow-x-hidden"
    >
      <div className="message hiden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
            Hello, How can I help you?
        </div>
      </div>

      <div className="message hidden flex items-end flex-row justify-end">
        <div className="ml-16 px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          Kindly email the standard broker brochure and photos of the interior
          and exterior especially the engine room and the engines.to
          keanu@whc.ai Thank you!
        </div>
      </div>

      <div className="mr-16 message hidden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
          Sure, I will have our sales team send you the requested materials. May
          I have your full name and phone number to assist you better?
        </div>
      </div>

      <div className="ml-16 message hidden flex items-end flex-row justify-end">
        <div className="px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          Keanu Reeves. I am in the Philippines and the better to communicate
          will thru email. Thank you.
        </div>
      </div>

      <div className="mr-16 message hidden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
          Thank you for the information, Keanu Reeves. I have forwarded your
          request to our sales team and they will email the requested brochure
          and photos to you soon.
        </div>
      </div>

      <div className="ml-16 message hidden flex items-end flex-row justify-end">
        <div className="px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          Thank you!
        </div>
      </div>

      <div className="mr-16 message hidden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
          You&apos;re welcome, Mr. Reeves! If you have any other questions, feel
          free to ask.
        </div>
      </div>

      <div className="ml-16 message hidden flex items-end flex-row justify-end">
        <div className="px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          Will do, your name please?
        </div>
      </div>

      <div className="mr-16 message hidden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
          My name is Nefi, your yacht information assistant. Feel free to ask if
          you have any other queries.
        </div>
      </div>

      <div className="ml-16 message hidden flex items-end flex-row justify-end">
        <div className="px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          Thanks much!
        </div>
      </div>

      <div className="mr-16 message hidden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
          You're welcome, Keanu! If there's anything else you need in the
          future, don't hesitate to reach out.
        </div>
      </div>

      <div className="ml-16 message hidden flex items-end flex-row justify-end">
        <div className="px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          If you are in California, that will be 7pm there.
        </div>
      </div>

      <div className="mr-16 message hidden flex items-end">
        <div className="flex flex-col space-y-2 text-md leading-tight max-w-lg mx-2 order-2 items-start px-4 py-3 rounded-xl rounded-bl-none bg-gray-100 text-gray-600 whitespace-pre-line">
          Indeed, there is a significant time difference between the Philippines
          and California. If you have any other inquiries or need further
          assistance, please do not hesitate to ask.
        </div>
      </div>

      <div className="ml-16 message hidden flex items-end flex-row justify-end">
        <div className="px-4 py-3 rounded-xl inline-block rounded-br-none bg-blue-500 text-white">
          Thank you!
        </div>
      </div>

    </div>
  )
}

export default MessagesComponent
