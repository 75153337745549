import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal";

const ConsistencyAndReliability = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 max-container"}>
            <div className={"md:flex relative w-full"}>
                <div className={"flex flex-col justify-center md:w-1/2"}>
                    <h2
                        className={"text-var-6xl text-left text-off-black font-bold leading-none pb-10"}
                    >
                        Consistency and Reliability
                    </h2>
                    <p
                        className={"text-var-2xl md:my-4 col-start-1 row-start-2 w-full md:w-auto lg:w-full"}
                    >
                        <Sentence>Experience consistent, top-notch customer service any time with the AI Yacht Chat. Unlike human-operated chats, our AI Yacht Chat remains patient, understanding, and is always ready to provide detailed explanations without any rush or irritation. Trust our AI Yacht Chat to assist you, no matter the inquiry or repetition&nbsp;needed.</Sentence>
                    </p>
                    <button
                        className={"relative inline-block text-center gradient-bg-purple hover:gradient-bg-fire text-white hover:text-black font-bold text-xl rounded-full ml-0 md:w-auto w-full py-4 mt-8 md:my-8 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                        onClick={() => {
                            setProduct(Product.AI_CHATBOT);
                            setShowModal(true);
                        }}
                    >
                        Get More Info
                    </button>
                </div>
                <div
                    className={"md:w-1/2 lg:w-1/3 md:ml-10 mt-10 md:mt-0 flex flex-row items-center justify-center md:inline-block"}
                >

                    <StaticImage
                        src={"../../images/yachting-ai-yacht-boat-ai-chat-bot-just-dreaming.png"}
                        alt={"AI Yacht Search - Yachting AI - WhiteCube.AI"}
                        placeholder={"blurred"}
                    />
                </div>
            </div>

            <RequestDemo
              // product={"AI Yacht ChatBot"}
              product={product}
              onClose={() => setShowModal(false)}
              show={showModal}
            />
        </section>
);
}

export default ConsistencyAndReliability
