import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";

const LearnWhy = () => {
    return (
        <section className={"py-16 md:py-32 p-5 max-container"}>
        <div className={"max-container"}>
          <div
            className={"flex flex-col md:flex-row relative justify-center w-full"}
          >
            <div
              className={"flex order-2 md:order-1 flex-col justify-center "}
            >
              <h2 className={"text-var-6xl text-center text-off-black font-bold leading-none pb-10"}>
                Learn Why AI Yacht Chat<br/> is Performing so Well

              </h2>
              <p className={"text-off-black text-center text-2xl"}>
                What makes our solution so good in lead capturing?
              </p>
              <a
                href="https://calendly.com/whitecube-ai/30min"
                target="_blank"
                className="relative mt-10 flex h-12 w-full mx-auto items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-pink-800 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="relative text-xl font-semibold text-off-white">Schedule a Demo</span>
              </a>
            </div>
            
          </div>
        </div>
      </section>
);
}

export default LearnWhy
