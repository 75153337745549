import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal";

const KeyFeatures = () => {
  const [product, setProduct] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
    return (
        <section className="text-gray-300 bg-gray-900 body-font">
        <div className="max-container py-16 md:py-32 p-5">
          <div className="text-center md:mb-20 mb-10">
            <h2 className="text-var-6xl text-center text-off-black font-bold leading-none pb-10">Key Features</h2>
            {/* <p className="leading-relaxed xl:w-2/4 lg:w-3/4 text-xl mx-auto text-gray-200 text-opacity-80"> */}
            {/*   This is whats make our AI ChatBot be so efficent in leads capture */}
            {/* </p> */}
            <div className="flex md:mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-pink-700 inline-flex"></div>
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div className="p-4 md:w-1/3 flex flex-col items-center">
              <div className="flex-grow">
                <h2 className="md:mt-20 text-2xl title-font font-medium md:text-right mb-1 gradient-text-fire">1. Relalible</h2>
                <p className="md:text-right leading-relaxed text-xl">Consistently high-quality, patient, understanding, reliable service.</p>


                <h2 className="text-2xl title-font font-medium md:text-right mb-1 gradient-text-fire mt-12">2. Consistent</h2>
                <p className="md:text-right leading-relaxed text-xl">AI Yacht Chat ensures consistent, expert-quality chat, surpassing untrained agents.</p>


                <h2 className="text-2xl title-font font-medium md:text-right mb-1 gradient-text-fire mt-12">3. Scallable</h2>
                <p className="md:text-right leading-relaxed text-xl">Operates 24/7 and is Resilient to Traffic Spikes</p>


                <h2 className="text-2xl title-font font-medium md:text-right mb-1 gradient-text-fire mt-12">4. Listings API</h2>
                <p className="md:text-right leading-relaxed text-xl">AI Yacht Chat real-time searches, presents yachts, boosts leads.</p>

              </div>
            </div>


            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <StaticImage
                src={"../../images/yachting-ai-yacht-chat-key-features.png"}
                alt={"Yachting.AI Tailored AI Yacht Browser"}
                placeholder="blurred"
                className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300"
              />
            </div>
            <div className="p-4 md:w-1/3 flex flex-col items-center">

              <div className="flex-grow">
                <h2 className="md:mt-20 text-2xl title-font font-medium text-left mb-1 gradient-text-fire">5. Knowledge Base</h2>
                <p className="text-left leading-relaxed text-xl">AI Yacht Chat customizable knowledge base, tailored to your company.</p>


                <h2 className="text-2xl title-font font-medium text-left mb-1 gradient-text-fire mt-12">6. Trained for Yachting</h2>
                <p className="text-left leading-relaxed text-xl">We use our own specially trained models for the yachting industry.</p>


                <h2 className="text-2xl title-font font-medium text-left mb-1 gradient-text-fire mt-12">7. CRM Integrations</h2>
                <p className="text-left leading-relaxed text-xl">Our AI ChatBot integrates with CRMs, auto-summarizes, and sends details.</p>


                <h2 className="text-2xl title-font font-medium text-left mb-1 gradient-text-fire mt-12">8. Human-Like Conversation</h2>
                <p className="text-left leading-relaxed text-xl"> AI Yacht Chat understands context, offers natural, lifelike interactions.</p>

              </div>
            
            </div>
          </div>
          <button

            className="text-off-white relative mt-20 flex h-12 w-full mx-auto items-center justify-center px-8 before:text-white before:absolute before:inset-0 before:rounded-full before:bg-pink-800 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            onClick={() => {
              setProduct(Product.AI_CHATBOT);
              setShowModal(true);
            }}
          >
          <span className="relative text-xl font-semibold text-off-white">Get More Info</span>
        </button>
        </div>

      <RequestDemo
        // product={"AI Yacht ChatBot"}
        product={product}
        onClose={() => setShowModal(false)}
        show={showModal}
      />
      </section>
);
}

export default KeyFeatures
