import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"

const IntegratesWithYourListingsAPI = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 bg-gray-900"}>
            <div className={"max-container"}>
                <div className={"md:flex relative justify-center w-full"}>
                    <div className={"md:w-1/2 lg:w-1/3"}>
                        <StaticImage
                            src={"../../images/yachting-ai-yacht-chat-integrates-with-your-listings-api.png"}
                            alt={"Yachting.AI Tailored AI Yacht Browser"}
                            placeholder="blurred"
                            objectFit="contain"
                            className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 h-full w-auto object-contain"
                        />
                    </div>
                    <div className={"md:w-1/2 md:ml-20 flex flex-col justify-center "}>
                        <h2
                            className={"text-var-6xl block font-black md:pb-10 md:pt-0 pt-10 lg:w-full gradient-text-fire"}
                        >
                            Integrates with Your Listings API
                        </h2>
                        <p
                            className={"text-var-2xl md:my-4 col-start-1 row-start-2 lg:w-full text-gray-300"}
                        >
                            <Sentence>
                                Thanks to its integration with the listings feed, AI Yacht Chat can in real-time search for yachts or charters of interest to the client. If during a conversation, a client expresses interest in specific yachts and asks for help in finding them, AI Yacht Chat, utilizing your listings API, will search and
                                present the client with yachts from your database.
                                <br />
                                <br />
                                This is a unique feature that not only increases traffic to your site and reduces bounce rate but also boosts the number of quality leads.</Sentence>
                        </p>
                        <button
                            className={"relative text-center gradient-bg-fire hover:gradient-bg-purple text-black hover:text-white font-bold text-xl rounded-full ml-0 mt-10 py-4 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                            onClick={() => {
                                setProduct(Product.AI_YACHT_BROWSER);
                                setShowModal(true);
                            }}
                        >
                            Get More Info
                        </button>
                    </div>
                </div>
            </div>

            <RequestDemo
                // product={"AI Yacht ChatBot"}
                product={product}
                onClose={() => setShowModal(false)}
                show={showModal}
            />
        </section>
);
}

export default IntegratesWithYourListingsAPI
