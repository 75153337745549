import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"

const SEOFriendly = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 max-container"}>
            <div className={"md:flex relative w-full"}>
                <div className={"flex flex-col justify-center md:w-1/2"}>
                    <h2
                        className={"text-var-6xl text-left font-bold leading-none pb-10"}
                    >
                        SEO-friendly
                    </h2>
                    <p
                        className={"text-var-2xl md:my-4 col-start-1 row-start-2 w-full md:w-auto lg:w-full"}
                    >
                        <Sentence>Our chat's core knowledge is sourced from the site's semantic meta tags,
                            prompting developers to optimize these tags for AI systems, including Google
                            Search. Sites with well-structured tags benefit from quicker chatbot
                            integration and encourage SEO improvements. Our AI Chat is also designed
                            for optimal page performance, using just a 10KB bundle resulting in only a 
                            15ms loading delay. Unlike bulkier chat solutions that can hinder website 
                            speed, our AI Yacht Chat ensures nearly no impact on rendering time, aligning 
                            with top scores on Google's Page Speed Insights.</Sentence>
                    </p>
                    <button
                        className={"relative inline-block text-center gradient-bg-purple hover:gradient-bg-fire text-white hover:text-black font-bold text-xl rounded-full ml-0 md:w-auto w-full py-4 mt-8 md:my-8 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                        onClick={() => {
                            setProduct(Product.AI_YACHT_SEARCH);
                            setShowModal(true);
                        }}
                    >
                        Get More Info
                    </button>
                </div>
                <div
                    className={"w-1/2 ml-10 flex flex-col justify-center hidden md:inline-block"}
                >
                    <StaticImage
                        src={"../../images/yachting-ai-yacht-boat-ai-chat-bot-seo-friendly-2.png"}
                        alt={"AI Yacht Search - Yachting AI - WhiteCube.AI"}
                    />
                </div>
            </div>
            <RequestDemo
              // product={"AI Yacht ChatBot"}
              product={product}
              onClose={() => setShowModal(false)}
              show={showModal}
            />
        </section>
);
}

export default SEOFriendly
