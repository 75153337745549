import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"

const PreTrainedForYachtingIndustry = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 bg-gray-900"}>
            <div className={"max-container"}>
                <div className={"md:flex relative justify-center w-full"}>
                    <div className={"flex md:w-1/2 lg:w-1/2"}>
                        <StaticImage
                            src={"../../images/yachting-ai-pre-trained-for-yachting-industry.png"}
                            alt={"Yachting.AI Tailored AI Yacht Browser"}
                            className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 w-full h-auto"
                            objectFit={"contain"}
                        />
                    </div>
                    <div className={"md:w-1/2 md:ml-20 flex flex-col justify-center "}>
                        <h2
                            className={"text-var-6xl text-left font-bold leading-none pb-10 md:pt-0 pt-10 gradient-text-fire"}
                        >
                            Pre-trained for Yachting Industry
                        </h2>
                        <p
                            className={"text-var-2xl md:my-4 col-start-1 row-start-2 lg:w-full text-gray-300"}
                        >
                            <Sentence>
                                Our AI Yacht Chat has been specifically designed and optimized for the Yachting Industry, catering both to brokerage firms selling yachts and to companies dealing with yacht charters.
                                <br />
                                <br />
                                It's not just a generic, one-size-fits-all chatbot solution; instead, it has been meticulously trained and tailored for businesses in the yachting sector.
                            </Sentence>
                        </p>

                        <button
                            className={"relative text-center gradient-bg-fire hover:gradient-bg-purple text-black hover:text-white font-bold text-xl rounded-full ml-0 py-4 mt-6 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                            onClick={() => {
                                setProduct(Product.AI_YACHT_BROWSER);
                                setShowModal(true);
                            }}
                        >
                            Get More Info
                        </button>
                    </div>
                </div>
            </div>

            <RequestDemo
                // product={"AI Yacht ChatBot"}
                product={product}
                onClose={() => setShowModal(false)}
                show={showModal}
            />
        </section>
);
}

export default PreTrainedForYachtingIndustry
