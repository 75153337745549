import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import { Sentence } from "../../components/StyledComponents"

import * as Product from "../../const/products"
import RequestDemo from "../../components/Common/RequestDemoModal"

const KnowledgeBase = () => {
    const [product, setProduct] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    return (
        <section className={"py-16 md:py-32 p-5 max-container"}>
            <div className={"md:flex relative w-full"}>
                <div className={"flex flex-col justify-center md:w-1/2"}>
                    <h2
                        className={"text-var-6xl text-left font-bold leading-none pb-10"}
                    >
                        Knowledge Base
                    </h2>
                    <p
                        className={"text-var-2xl md:my-4 col-start-1 row-start-2 w-full md:w-auto lg:w-full"}
                    >
                        <Sentence>
                            AI Yacht Chat has an internal knowledge base that can be fueled with information specific to your company. This could be up-to-date information about events or detailed information about offices, locations, employees, brokers, or guides. Anything the AI consultant should know can be taught.
                        </Sentence>
                    </p>
                    <button
                        className={"relative inline-block text-center gradient-bg-purple hover:gradient-bg-fire text-white hover:text-black font-bold text-xl rounded-full ml-0 md:w-auto w-full py-4 mt-8 md:my-8 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                        onClick={() => {
                            setProduct(Product.AI_YACHT_SEARCH);
                            setShowModal(true);
                        }}
                    >
                        Get More Info
                    </button>
                </div>
                <div
                    className={"w-1/2 ml-10 flex flex-col justify-center hidden md:inline-block"}
                >
                    <StaticImage
                        src={"../../images/yachting-ai-yacht-chat-knowledge-base.png"}
                        alt={"AI Yacht Search - Yachting AI - WhiteCube.AI"}
                    />
                </div>
            </div>

            <RequestDemo
                // product={"AI Yacht ChatBot"}
                product={product}
                onClose={() => setShowModal(false)}
                show={showModal}
            />
        </section>
);
}

export default KnowledgeBase
